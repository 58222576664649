.titers {
    width: 100%;
    display: inline-block;
    overflow: visible;
    position: relative;
    padding: rem(32);
    background: $white;
    overflow: hidden;
    margin-top: rem(140);
    @media(max-width: $mobileSmall) {
		margin-top: rem(96);
	}

    .animate-circles {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        div {
            position: absolute;
            border-radius: 100%;
            background: $primary2;
            filter: blur($blurValue);
            
            &:first-child {
                width: rem(700);
                height: rem(700); 
                top: rem(-200);
                left: rem(-385);
                background: $snuff;
            }
            &:nth-child(2) {
                width: rem(400);
                height: rem(400);
                top: rem(-70);
                left: 50%;
                background: $disabled;
            }
            &:last-child {
                width: rem(645);
                height: rem(645);
                top: rem(-255);
                right: rem(-392);
                background: $bizarre;
            }
        }
    }

    @media(max-width: $maxWidthContainer) {
		padding: rem(40) 0;
	}
  
    &__main {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
  
    &__list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-name: titers;
        will-change: transform;
    }
  
    &__item {
        @extend %Manrope96-56-medium;
        white-space: nowrap;
        line-height: 1.2;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        margin-right: rem(32);
    }
}

@-webkit-keyframes titers {
    0%{-webkit-transform: translate(0, 0);}
    100%{-webkit-transform: translate(-100%, 0);}
}
@keyframes titers{
    0%{transform: translate(0, 0);}
    100%{transform: translate(-100%, 0)}
}
  