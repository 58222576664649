html, body {
	height: 100%;
	min-width: $minWidth + px;
}

body {
	line-height: 1;
	font-family: $defaultFontFamily;
	font-size: $fontSize;
	color: $black;
	overflow-x: hidden;
	text-rendering: optimizeLegibility;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: $white;

	.lock & {
		overflow: hidden;
	}
}

// * * * Page Layout
.wrapper {
	min-height: 100%;
	overflow: hidden;
	display: flex;
    flex-direction: column;
    justify-content: space-between;
	position: relative;
	z-index: z('wrapper');

	// * Page is loaded
	.loaded & {
	}
}

.page {
	min-width: 0;
	// margin-top: rem(88);
	// @media(max-width: $mobileSmall) {
	// 	margin-top: rem(76);
	// }
}

// * Page container
@if ($responsiveType==1) {
	// Отзывчивая
	._container {
		@if ($maxWidthContainer>0) {
			max-width: rem($maxWidthContainer);
			box-sizing: content-box;
			margin: 0 auto;

			// @media(min-width: em(1920)) {
			// 	max-width: rem($hdWidth);
			// }
		}
		@if ($containerPadding>0) {
			padding: 0 rem(math.div($containerPadding, 2));
		}
	}
} @else {
	// По брейк-поинтам
	._container {
		margin: 0 auto;
		@if ($maxWidthContainer>0) {
			max-width: rem($maxWidthContainer);
			box-sizing: content-box;
		} @else {
			@if ($containerPadding>0) {
				padding: 0 rem(math.div($containerPadding, 2));
			}
		}
		@media (max-width: $pc) {
			max-width: rem(970);
		}
		@media (max-width: $tablet) {
			max-width: rem(750);
		}
		@media (max-width: $mobile) {
			max-width: none;
			@if ($containerPadding>0 and $maxWidthContainer>0) {
				padding: 0 rem(math.div($containerPadding, 2));
			}
		}
	}
}

// * * * Other styles

// * fullscreen block
[data-fullscreen] {
	height: 100vh;
	.touch & {
		height: calc(var(--vh, 1vh) * 100);
		@media (max-width: $mobile) {
			transition: height 0.2s;
		}
	}
}

// * IBG
// Сниппет (HTML): ibg (div c картинкой и классами)
// Сниппет (HTML): ibga (a c картинкой и классами)
[class*="__image-ibg"] {
	position: relative;
	img {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
	}
}

[data-spoller] svg:not(:first-child) {
	width: rem(12);
}

[data-favorite] {
	cursor: pointer;
	&._active {
		svg path {
			fill: $woodsmoke;
		}
	}
	svg {
		width: 16px;
	}
	svg path {
		fill: none;
		stroke: $woodsmoke;
		transition: fill $defaultTransition;
	}
}

.btn-centered {
	display: flex;
    flex-direction: column;
	.primary-btn {
        @media(min-width: $tablet) {
            margin: rem(100) auto 0 auto;
        }
    }
}

.s-mb {
	margin-bottom: rem(168);

    @media(max-width: $mobileSmall) {
        margin-bottom: rem(92);
    }
}
