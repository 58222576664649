// * HOME
.home-page {
    .intro {
        height: 100vh;
        overflow: hidden;
        @media(max-width: $tablet) {
            min-height: rem(720);
        }

        &__content {
            display: flex;
            height: 100%;
            width: 100%;

            @media(max-width: $tablet) {
                flex-direction: column;
            }
        }

        &__item {
            width: 35.5%;
            min-width: rem(280);
            &:first-child {
                .intro-item__content {
                    margin-left: calc((100vw - $maxWidthContainerPX) / 2);
                    padding-right: rem(32);
                    @media(max-width: $pc) {
                        margin-left: rem(20);
                    }
                    @media(max-width: $tablet) {
                        padding-right: rem(20);
                    }
                }
            }
            &:nth-child(2) {
                width: 29%;
                .intro-item__content {
                    padding-left: rem(32);
                    padding-right: rem(32);
                    @media(max-width: $tablet) {
                        padding-right: rem(20);
                        padding-left: rem(20);
                    }
                }
            }
            &:last-child {
                .intro-item__content {
                    margin-right: calc((100vw - $maxWidthContainerPX) / 2);
                    padding-left: rem(32);
                    @media(max-width: $pc) {
                        margin-right: rem(20);
                    }
                    @media(max-width: $tablet) {
                        padding-left: rem(20);
                    }
                }
            }

            &._active {
                width: 62%; 

                &:after {
                    background: rgba($black, 0);
                }
                .intro-item__head > * {
                    display: block;
                    visibility: visible;
                    opacity: 1;
                    transform: translate3d(0, 0, 0) scaleY(1);
                }
            }
            &._reduced {
                width: 19%;
                .intro-item__head > * {
                    transition-delay: 0s!important;
                }

                .primary-btn {
                    opacity: 0;
                    transform: scale(0);

                    @media(max-width: $tablet) {
                        flex: 0 0 rem(96);
                        width: rem(96);
                        height: rem(96);
                    }
                }
            }

            @media(max-width: $tablet) {
                width: 100%;
                height: 35.5%;
                min-height: rem(140);
                min-width: 0;

                &:nth-child(2) {
                    width: 100%;
                }
                &._active {
                    width: 100%; 
                    height: 62%;
                }
                &._reduced {
                    width: 100%;
                    height: 19%;
                }
            }
        }

        .intro-item {
            transition: all .7s ease .7s;
            position: relative;
            cursor: pointer;
            overflow: hidden;

            &:hover {
                &:after {
                    background: rgba($black, 0.6);
                }
            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                transition: all $defaultTransition;
                background: rgba($black, 0.5);
                z-index: -1;
            }

            &__bg {
                transition: all .7s;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                z-index: -1;
            }

            &__content {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                color: $white;
                padding: rem(112) 0 rem(80) 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                @media(max-width: $tablet) {
                    padding: rem(62) 0 rem(24) 0;
                    justify-content: end;

                    .primary-btn {
                        align-self: flex-end;
                        justify-self: flex-end
                    }
                }

                h2 {
                    @extend %Manrope56-40-medium;
                    margin-bottom: rem(24);
                    @media(max-width: $mobileSmall) {
                        font-size: rem(32);
                        margin-bottom: rem(16);
                    }
                }

                p {
                    @extend %Montserrat16-medium;
                   
                    &:not(:last-of-type) {
                        margin-bottom: rem(8);
                    }
                }

                ._container {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 0;

                    @media(max-width: $tablet) {
                        justify-content: end;
                    }
                }
            }

            &__head {
                margin-bottom: rem(24);
                max-width: rem(280);

                & > * {
                    transition: all .7s ease 1.4s;
                    visibility: hidden;
                    opacity: 0;
                    transform: translate3d(0, 20px, 0) scaleY(1.1);
                }
            }
        }
    }
}

// * BOYS
.boys-page {
    color: $white;
}

// * CORPORATES
.corporates-page {
    color: $woodsmoke;
}

// * BOYS-FILTER-PAGE
.boys-filter-page {
    color: $white;
    background: $shark;
}

// * CORP-FILTER-PAGE
.corp-filter-page {
    .filter-body__filter-type .filter-spoller__body {
        background: $primary4;
    }
}

// * PACKAGE-PAGE
.package-card-page {
    .geometry-media__svg {
        top: -20%;
        z-index: 2;
    }
    .page-anchor_top {
        margin: rem(-75) auto rem(125) auto;
        @media(max-width: $mobileSmall) {
            margin: rem(56) auto;
        }
    }

    .like-btn {
        border-color: $woodsmoke;
    }

    &_boys {
        background: $woodsmoke;
        color: $white;

        .geometry-media__svg {
            top: 50%;
            left: 50%;
            z-index: -1;
        }
    }
    &_corp {

        .geometry-media__svg {
            top: -16%;
            left: 46%;
            z-index: -1;
        }
    }
}

// * PRODUCT-PAGE
.product-card-page {
    .page-anchor_top {
        margin: rem(-75) auto rem(125) auto;
        @media(max-width: $mobileSmall) {
            margin: rem(56) auto;
        }
    }
    .like-btn {
        border-color: $woodsmoke;
    }
}

// * ORDER-PAGE
.order-page {
    .page-grid {
        margin-bottom: rem(200);

        @media(max-width: $mobileSmall) {
            margin-bottom: rem(56);
        }
    }
}

// * TIPS
.tips-page {
    .page-section {
        margin: rem(112) 0 rem(20) 0;
        @media(max-width: $mobileSmall) {
            margin: rem(56) 0 0 0;
        }
    }
    .filter-body {
        margin-bottom: rem(200);
        @media(max-width: $mobileSmall) {
            margin-bottom: rem(96);
        }
    }
}

// * SINGLE TIP PAGE
.single-tip-page {
    .page-section {
        &:nth-child(2) {
            margin-top: rem(112);
            @media(max-width: $tablet) {
                margin-bottom: rem(40);
            }

            .page-grid {
                margin: 0;
            }
        }
        &:nth-child(3) {
            margin-top: rem(80);
            @media(max-width: $tablet) {
                margin-bottom: rem(56);
            }

            .page-grid {
                margin: 0;
            }
        }
        &:nth-child(4) {
            margin-top: rem(80);
            @media(max-width: $tablet) {
                margin-bottom: rem(56);
            }

            .page-grid {
                margin: 0;
            }
        }
        &:last-child {
            margin-bottom: rem(200);
            @media(max-width: $tablet) {
                margin-bottom: rem(96);
            }
        }
    }
}

// * CONTACTS PAGE
.contacts-page {
    .page-section {
        &:nth-child(2) {
            margin-top: rem(72);
            @media(max-width: $mobileSmall) {
                margin-top: rem(48);
            }
            .page-content {
                @media(max-width: $tablet) {
                    margin-bottom: rem(24);
                }
            }
        }
        &:last-child {
            margin-bottom: rem(200);
            @media(max-width: $tablet) {
                margin-bottom: rem(96);
            }
            .page-content__image-ibg {
                padding-bottom: 132%;
            }
        }
    }
    .form__controls {
        display: flex;
        justify-content: flex-end;
        padding-top: rem(40);
    }
}

// * PAGE UTILS
.page-utils {
    @extend %flex-center;
    &__content {
        @extend %flex-center;
        flex-direction: column;
        position: relative;
        max-width: rem(614);
        margin: rem(96) 0 rem(200) 0;
        padding-top: rem(48);
        @media(max-width: em($maxWidthContainer)) {
            padding: rem(32) rem(20) 0 rem(20);
            margin: rem(96) 0 rem(96) 0;
        }
        img {
            @extend %absolute-center;
            z-index: -1;
        }
        h1 {
            @extend %Manrope280-120-medium;
            -webkit-text-stroke: 2px $bazaar;
            margin-bottom: rem(24);
            color: transparent;
        }
        h2 {
            @extend %Manrope88-40-medium;
            color: $bazaar;
            margin-bottom: rem(24);
            @media(max-width: $mobile) {
                margin-bottom: rem(16);
            }
        }
        strong {
            @extend %Montserrat18-16-medium;
            margin-bottom: rem(8);
        }
        p {
            @extend %Montserrat18-14-medium;
            color: $bazaar;
            margin-bottom: rem(48);

            @media(max-width: $tablet) {
                margin-bottom: rem(40);
            }
        }
        .primary-btn {
            @media(max-width: $mobile) {
                align-self: end;
            }
        }
    }
    &__link {
        @extend %flex-center;
        @extend %Montserrat16-14-light;
        flex-direction: column;
        margin-bottom: rem(56);

        a {
            text-decoration: underline;
        }
    }
}

// *  POLICY PAGE
.policy-page {
    &__content {
        margin: rem(72) 0 rem(200) 0;
        @media(max-width: $mobileSmall) {
            margin: rem(32) 0 rem(96) 0;
        }

        h1 {
            @extend %Manrope56-20-medium;
            max-width: rem(488);
            margin-bottom: rem(48);
            @media(max-width: $mobileSmall) {
                margin-bottom: rem(16);
            }
        }
    }
    &__text {
        &:not(:last-child) {
            margin-bottom: rem(48);
        }
        h2 {
            @extend %Montserrat24-18-medium;
            color: $bazaar;
            &:not(:last-child) {
                margin-bottom: rem(24);
                @media(max-width: $mobileSmall) {
                    margin-bottom: rem(24);
                }
            }
        }
        h3 {
            @extend %Montserrat18-16-medium;
            color: $bazaar;
            &:not(:last-child) {
                margin-bottom: rem(24);
                @media(max-width: $mobileSmall) {
                    margin-bottom: rem(24);
                }
            }
        }
        p {
            @extend %Montserrat16-14-light;
            color: $grey;
            &:not(:last-child) {
                margin-bottom: rem(32);
                @media(max-width: $mobileSmall) {
                    margin-bottom: rem(16);
                }
            }
        }
    }
} 

