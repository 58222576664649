.tabs {
	display: flex;
    flex-direction: column;
    justify-content: center;

	&__body-title {
		@extend %Montserrat24-14-medium;
		color: $bazaar;
		margin: rem(56) 0;
		padding-bottom: em(16);
		border-bottom: 1px solid $primary1;

		@media(max-width: $mobileSmall) {
			display: none;
		}
	}
	&__body-subtitle {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: em(24);
		border-bottom: 1px solid $primary1;

		@media(max-width: $mobileSmall) {
			margin-top: rem(24);
			padding-bottom: em(8);

			a, button {
				display: none;
			}
		}

		span {
			color: $grey;
			text-transform: uppercase;
		}
		button, a {
			@extend %Montserrat14-12-medium;
			color: $light-grey;
			text-decoration: underline;
			text-transform: uppercase;
		}
	}
	&__navigation {
		display: inline-grid;
		grid-template-columns: repeat(3, auto);
		gap: rem(64);
		margin: 0 auto;
		@media(max-width: $mobileSmall) {
			display: grid;
			width: 100%;
			grid-template-columns: repeat(3, 1fr);
			gap: 0;
		}
	}
	&__title {
		color: $bazaar;
		font-size: rem(10);
		line-height: 100%;
		font-weight: 500;
		text-transform: uppercase;

		&:nth-child(2) {
			border-right-width: 0;
			border-left-width: 0;
		}
		
		@media(min-width: $mobileSmall) {
			text-transform: uppercase;
			font-size: rem(14);
			border-bottom: 4px solid transparent;
			padding-bottom: rem(16);
		}
		@media(max-width: $mobileSmall) {
			height: rem(40);
			padding: rem(6);
			border: 1px solid $bazaar;
		}

		&._tab-active {
			border-color: $bazaar;

			@media(max-width: $mobileSmall) {
				background-color: $disabled;
			}
		}
	}
	&__content {
		@media(min-width: $mobileSmall) {
			border-top: 1px solid $primary1;
		}
	}
	&__body {
		&._active {
			display: block;
			animation: showBlock 0.6s ease-in-out;
			@keyframes showBlock {
				0% {
					opacity: 0.25;
				}
				100% {
					opacity: 1;
				}
			}
		}
	}
}











._tabs-block {
	display: none;

	&._active {
		display: block;
		animation: showBlock 0.6s ease-in-out;
		@keyframes showBlock {
			0% {
				opacity: 0.25;
			}
			100% {
				opacity: 1;
			}
		}
	}
	@media(max-width: $mobile) {
		.tabs-grid > *:not(:nth-child(-n+4)) {
			display: none;
		}
	}
	&.show-more {
		.tabs-grid > * {
			display: flex;
		}
	}
}
.tabs-block {

	&__nav-wrap {
		display: flex;
		overflow: auto;
	}
	&__item {
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		//transition: color $tf2, border-color $tf2;
		border-bottom: 1px solid transparent;
		position: relative;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 100%;
			height: 1px;
			width: 100%;
			transform-origin: 100% 100%;
			transform: scale3d(0, 1, 1);
			transition: transform 0.3s ease;
		}

		&:hover {
			&:before {
				transform-origin: 0 0;
				transform: scale3d(1, 1, 1);
			}
		}

		span {
			margin-left: rem(6);
		}

		&:not(:last-child) {
			margin-right: rem(40);
		}

		&._active {
			color: $bazaar;
			border-color: $red;
		}
	}
	&__body {
		margin-top: rem(40);
	}
	.tabs-grid {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(rem(288), 1fr));
		gap: rem(40);
	}
	.square-view-all {
		overflow: hidden;
		width: rem(288);
		height: rem(288);
		display: none;
		margin-top: rem(20);
		margin: rem(40) auto 0 auto;

		@media(max-width: $mobile) {
			display: block;
		}
	
		div {
			@extend %flex-center;
			width: 100%;
			height: 100%;
		}
	}
}

._tabs-mobile {
	position: relative;
	.tabs-block__nav {
		overflow: hidden;
	}
	@media(min-width: 768px) {
		.tabs-mobile__value {
			display: none;
		}
		.tabs-block__nav {
			display: flex!important;
		}
	}
	@media(max-width: 768px) {
		.tabs-block__nav {
			display: none;
			position: absolute;
			top: 100%;
			left: 0;
			max-width: 100%;
			max-height: rem(122);
			z-index: 2;
			overflow: auto;

			&-wrap {
				display: flex;
				flex-direction: column;
				padding: rem(14) rem(28) rem(14) rem(14);
			}

			.tabs-block__item {
				display: flex;
				justify-content: space-between;
				margin: 0;
				&:not(:last-child) {
					margin-bottom: 8px;
				}
				// &._active {
				// 	color: $green;
				// 	border-color: $green;

				// 	span {
				// 		color: $green;
				// 	}
				// }
			}
		}

		.tabs-mobile__value {
			background-color: transparent;
			cursor: pointer;
			border-radius: 6px;
			height: 56px;
			display: flex;
			align-items: center;

			&:after {
				content: '';
				transition: all 0.3s ease 0s;
				background: url('../img/interface/spoller-arrow.svg') center no-repeat;
				width: rem(16);
				height: rem(16);
				margin-left: rem(8);
			}

			&._active {
				&:after {
					transform: rotate(-180deg);
				}
			}
		}
	}
}
