.hystmodal__opened {
    position: fixed;
    right: 0;
    left: 0;
    overflow: hidden;
}
.hystmodal__shadow{
    position: fixed;
    border:none;
    display: block;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    pointer-events: none;
    z-index: 98;
    opacity: 0;
    transition: opacity 0.5s ease;
    background-color: black;
}
.hystmodal__shadow--show {
    opacity: 0.6;
}
.hystmodal__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem(48);

    h5 {
        text-transform: uppercase;
    }
}
.hystmodal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    opacity: 1;
    pointer-events: none;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    z-index: 99;
    visibility: hidden;
    color: $grey;
}
.hystmodal--active{
    opacity: 1;
}
.hystmodal--moved,
.hystmodal--active{
    pointer-events: auto;
    visibility: visible;
}
.hystmodal__wrap {
    flex-shrink: 0;
    flex-grow: 0;
    width: 100%;
    min-height: 100%;
    margin: auto;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding: rem(16);
}
.hystmodal__window {
    padding: rem(40);
    box-sizing: border-box;
    flex-shrink: 0;
    flex-grow: 0;
    background: $white;
    width: 100%;
    max-width: rem(536);
    overflow: visible;
    transition: transform 0.3s ease 0s, opacity 0.3s ease 0s;
    transform: scale(0.9);
    opacity: 0;
    border: 1px solid $white;
    overflow: hidden;
    @media(max-width: $mobile) {
        padding: rem(12) rem(20);
    }

    .form__controls {
        display: flex;
        justify-content: center;
        margin-top: rem(32);
        @media(max-width: $mobileSmall) {
            margin-top: rem(40);
            justify-content: flex-end;
        }
    }
}
.hystmodal--active .hystmodal__window {
    transform: scale(1);
    opacity: 1;
}
.hystmodal__close {
    width: rem(24);
    height: rem(24);
    background-position: center center;
    background-repeat: no-repeat;
    padding: rem(10);
    background-image: url("../img/interface/close.svg");
    font-size: 0;
    cursor: pointer;
    outline: none;
    border: 1px solid $white;
}

.page-cart {
    .hystmodal__wrap {
        align-items: flex-end;
        justify-content: end;
        flex-flow: none;
        padding: 0;
        margin: 0;
    }
    .hystmodal__window {
        height: 100%;
        min-width: 51vw;
        transform: scale(1);
        border: none;
        padding-top: rem(32);
        padding-bottom: rem(24);
        padding-left: rem(32);
        position: relative;

        &:before {
            content: '';
            display: block;
            height: rem(104);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            background: $silver;
            z-index: 0;

            @media(max-width: $mobile) {
                height: rem(138);
            }
            @media(max-width: $mobileSmall) {
                height: rem(132);
            }
        }

        @media(min-width: $maxWidthContainerPX) {
            padding-right: calc((100vw - $maxWidthContainerPX) / 2);
        }

        @media(max-width: $maxWidthContainerPX) {
            min-width: 100vw;
            padding-left: rem(20);
            padding-right: rem(20);
            padding-top: rem(12);
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $light-grey;
        padding-bottom: rem(16);
        margin-bottom: rem(32);

        @media(max-width: $mobile) {
            padding-bottom: rem(12);
        }

        span {
            @extend %Montserrat18-14-medium;
            color: $grey;
        }
    }
    &__body {
        flex: 1 1 100%;
        overflow: auto;

        & > span {
            &:not(:last-child) {
                display: none;
            }

            @extend %Montserrat18-14-medium;
            margin: 0 auto;
            color: $medium-grey;
        }
    }
    &__controls {
        display: flex;
    }
    &__bottom {
        margin-top: rem(64);
    }
    &__price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: rem(56);

        @media(max-width: $mobile) {
            margin-bottom: rem(48);
        }

        span {
            &:first-child {
                @extend %Montserrat18-12-regular;
                text-transform: uppercase;
            }
            &:last-child {
                @extend %Montserrat24-20-medium;
                font-weight: 600;
                line-height: 100%;
                color: $bazaar;
            }
        }
    }
    &__actions {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: rem(32);

        @media(max-width: $mobile) {
            grid-template-columns: 1fr;
            gap: rem(8);
        }
    }
}