* {
	padding: 0px;
	margin: 0px;
	border: 0px;
}
*,
*:before,
*:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	// -webkit-tap-highlight-color: rgba(0,0,0,0);
  	-webkit-tap-highlight-color: transparent;
}
::selection {
    background-color: #9F8888;
    color: white;
}
::-moz-selection {
    background-color: #9F8888;
    color: white;
}
:focus,
:active {
	outline: none;
}
a:focus,
a:active {
	outline: none;
}

a {
	color: inherit;
	cursor: pointer;
}
a:link,
a:visited {
	text-decoration: none;
}
a:hover {
	text-decoration: none;
}
ul li {
	list-style: none;
}
img {
	vertical-align: top;
	max-width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: inherit;
	font-size: inherit;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus {
//   border: none;
//   background-color: none;
//   -webkit-text-fill-color: #000;
  -webkit-box-shadow: none;
  transition: background-color 50000s ease-in-out 0s;
}
input,
button,
textarea {
	font-family: $defaultFontFamily;
	font-size: inherit;
}
button {
	cursor: pointer;
	color: inherit;
	background-color: inherit;
}
input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	resize: none;
	&:focus {
		outline: none;
	}
}
