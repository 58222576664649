.form {
    
    &__wrapper {
        max-width: 100%;
    }
    &__item {
        position: relative;
        transition: all $defaultTransition;
        &:not(:last-child) {
            margin-bottom: rem(44);
        }
        &:not(.form__item_textarea) {
            border-bottom: 1px solid $woodsmoke;
        }

        &._error {
            border-color: $red;
        }
        &._focus {
            border-color: $primary1;
        }

        input {
            height: 100%;
            width: 100%;
            background: none;
            padding-bottom: rem(16);
            outline: none;

            @include placeholder-content {
                @extend %Montserrat16-12-light;
                text-transform: uppercase;
            }
        }
        &_textarea {

            span {
                @extend %Montserrat14-12-light;
                color: $light-grey;
                display: block;
                margin-bottom: rem(8);
            }
            &._focus {
                textarea {
                    border-color: $primary1;
                }
            }

        }
        textarea {
            width: 100%;
            height: rem(100);
            padding: rem(12);
            background: transparent;
            border: 1px solid $light-grey;

            @include placeholder-content {
                text-transform: uppercase
            }
        }

        // * 

        // .incorrect-field._active {
        //     opacity: 1;
        // }
        // .required-field._active {
        //     opacity: 1;
        // }
        // .error-text {
        //     opacity: 0;
        //     transition: opacity .35s ease-in-out;
        //     position: absolute;
        //     top: 50%;
        //     right: 0;
        //     color: red;
        //     font-size: 14px;
        // }
        &_date {
            border: none!important;

            .form__subtitle {
                display: block;
                margin-bottom: rem(12);
            }
        }
        &_check {
            color: $grey;
            border: none!important;
            margin-bottom: rem(56);

            .form__subtitle {
                display: block;
                margin-bottom: rem(24);
            }
        }
    }
    &__controls {
        display: grid;
        grid-template-columns: 1fr;
        gap: rem(8);
        @media(max-width: $mobile) {
            margin-top: rem(40);
        }
    }
    &__subtitle {
        @extend %Montserrat16-12-light;
        color: $grey;
        text-transform: uppercase;
    }
    &__date-field {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: rem(20);
        @media(max-width: $mobile) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media(max-width: $mobileSmall) {
            grid-template-columns: 1fr;
        }

        .select {
            &:nth-child(1) {
                z-index: 3;
            }
            &:nth-child(2) {
                z-index: 2;
            }
            &:nth-child(3) {
                z-index: 1;
            }
        }
    }
    &__actions {
        display: grid;
        grid-template-columns: 1fr;
        gap: rem(32);
    }
    &__price {
        border: none!important;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            &:first-child {
                @extend %Montserrat18-12-regular;
                text-transform: uppercase;
            }
            &:last-child {
                @extend %Montserrat24-20-medium;
                font-weight: 600;
                line-height: 100%;
                color: $bazaar;
            }
        }
    }
    &__preview {
        @media(max-width: $tablet) {
            margin-bottom: rem(40);
        }
    }
    // * ERROR
    &__error {
        @extend %Montserrat12-regular;
        color: $red;
        position: absolute;
        top: 110%;
    }
}

.account-form {
    &__viewpass {
        position: absolute;
        top: rem(36);
        right: rem(16);
        width: rem(24);
        height: rem(24);
        cursor: pointer;

        @media(max-width: $mobileSmall) {
            top: rem(32);
            right: rem(14);
        }

        &.active {
            &:before {
                background-image: url('../img/interface/pass-1.svg');
            }
        }
        &:before {
            content: '';
            display: block;
            width: rem(24);
            height: rem(24);
            background-image: url('../img/interface/pass-2.svg');
        }
    }
    &__items {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(rem(260), 1fr));
        gap: rem(32);
        margin: rem(40) 0;

        .account-form__item {
            margin: 0!important;
        }
    }
    &__item {
        display: flex;
        flex-direction: column;
        position: relative;
        &:not(:last-child) {
            margin-bottom: rem(24);
        }
        label {
            @extend %Montserrat16-12-light;
            color: $bazaar;
            text-transform: uppercase;
            margin-bottom: em(4);
        }
        input {
            height: rem(54);
            border: 1px solid $bazaar;
            transition: all $defaultTransition;
            padding: em(14) em(58) em(14) em(14);

            @media(max-width: $mobileSmall) {
                height: rem(46);
                padding: em(10);
            }
        }

        .form__error {
            bottom: 0;
            right: 0;
        }

        &._error {
            input {
                border-color: $red;
            }
        }
        &._focus {
            input {
                border-color: $woodsmoke;
            }
        }
    }
    &__link {
        @extend %Montserrat14-ligth;
        color: $bazaar;
        margin-top: em(16);
        margin-left: auto;
        text-decoration: underline!important;
        @media(max-width: $mobileSmall) {
            margin-top: em(8);
        }
    }
    &__controls {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(rem(200), 1fr));
        gap: rem(32);
        margin-top: rem(56);
        @media(max-width: $mobileSmall) {
            margin-top: rem(48);
            grid-template-columns: 1fr;
            gap: rem(8);
            
            & > *:last-child {
                order: -1;
            }
        }
    }
    .select {
        .select__title {
            border-color: $bazaar;
        }
    }
    .checkbox__text {
        @extend %Montserrat18-12-light;
        text-transform: none;
        color: $grey;
    }
    .icon-check {
        align-self: auto!important;
    }
    &__info {
        color: $grey;

        .dot {
            @extend %Montserrat18-12-light;
            display: flex;
            align-items: center;
           
            &:before {
                content: '';
                display: block;
                flex: 0 0 rem(4);
                width: rem(4);
                height: rem(4);
                background: $grey;
                border-radius: 100%;
                margin-right: em(24);
            }
        }
    }
}

.profile-form {
    margin-top: rem(48);

    @media(max-width: $mobileSmall) {
        margin-top: rem(24);
    }

    &__body {
        display: grid;

        @media(min-width: $mobileSmall) {
            grid-template-columns: minmax(rem(150), rem(186)) minmax(rem(320), rem(384)) minmax(rem(320), rem(384));
            column-gap: rem(131);
            row-gap: rem(56);
		}
        @media(max-width: em($maxWidthContainer)) {
            grid-template-columns: 1fr 2fr 2fr;
            column-gap: rem(24);
        }
        @media(max-width: $tablet) {
            grid-template-columns: 1fr;
            row-gap: rem(24);
        }
    }
    &__item {
        display: flex;
        flex-direction: column;
        position: relative;

        label {
            @extend %Montserrat16-12-light;
            color: $bazaar;
            text-transform: uppercase;
            margin-bottom: em(12);
            @media(max-width: $mobileSmall) {
                margin-bottom: em(4);
            }
        }
        input {
            height: rem(50);
            border: 1px solid $bazaar;
            transition: all $defaultTransition;
            padding: em(12) em(58) em(12) em(16);

            @media(max-width: $mobileSmall) {
                height: rem(46);
            }

            &:disabled {
                cursor: not-allowed;
                background-color: transparent;
                border: none;
                height: rem(32);
                border-bottom: 1px solid $bazaar;
                padding: 0;
                margin-top: rem(8);
            }
            &[data-date-mask] {
                cursor: pointer;
            }
        }

        &._focus {
            input {
                border-color: $woodsmoke;
            }
        }

        &._disable {
            .select {
                margin-top: rem(8);
            }
            .select__title {
                border: none;
            }
            .select__value {
                cursor: not-allowed;
                padding: 0;
                height: rem(32);
                border: none;
                border-bottom: 1px solid $bazaar;
                &:before {
                    display: none;
                }
            }
            .select__content {
                margin-right: auto;
            }
        }
    }
    &__link {
        @extend %Montserrat14-ligth;
        color: $bazaar;
        margin-top: em(16);
        margin-left: auto;
        text-decoration: underline!important;
        @media(max-width: $mobileSmall) {
            margin-top: em(8);
        }
    }
    &__controls {
        display: flex;
        justify-content: flex-end;
        margin-top: rem(44);
        @media(max-width: $mobileSmall) {
            margin-top: rem(48);
        }
    }
    &__link {
        @extend %Montserrat14-ligth;
        color: $medium-grey;
        text-decoration: none;
        text-decoration: underline;
    }
    .select {
        .select__title {
            border-color: $bazaar;
        }
    }

    // modeficators
    &_edit {
        .profile-form__item {

        }
    }
}

@import "checkbox";
@import "select";
@import "quantity";
// @import "radio";
// @import "rating";
