.header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	min-height: rem(94);
	color: $white;
	background: transparent;
	z-index: z('header');
	display: flex;	
	transition: all $defaultTransition;

	&:hover {
		background: $woodsmoke;
	}

	@media (max-width: $mobileSmall) {
		min-height: rem(48);
	}

	& > * {
		width: 100%;
	}
	

	// * header__content
	&__content {
		display: flex;
		align-items: center;
		padding: rem(31) 0;
		height: 100%;
		@media (max-width: $mobileSmall) {
			padding: rem(12) 0;
		}
	}

	// * header__icon-menu
	&__icon-menu {
		margin-right: rem(20);
	}

	// * header__content
	&__logo {
		margin-right: em(56);
		display: flex;
		align-items: center;

		@media (max-width: $tablet) {
			margin-right: rem(24);
		}

		@media (min-width: $tablet) and (max-width: 1100px) {
			font-size: rem(8);
		}

		a {
			& > * {
				width: rem(99);
				vertical-align: middle;
			}
			svg path {
				transition: fill $defaultTransition;
			}
			&:hover {
				svg path {
					fill: $bazaar;
				}
			}
		}
	}

	// * header__menu
	&__menu {
		display: flex;
		align-items: center;
		margin-right: em(24);
	}

	.menu__subitem {
		[data-spoller] + * {
			min-width: rem(133);
		}
	}

	// * header__actions
	&__actions {
		margin-left: auto;
		display: grid;
		align-items: center;
		grid-template-columns: repeat(3, auto);
		gap: em(24);
		@media (max-width: $tablet) {
			display: none;
		}
	}
	&__phone {
		@extend %Montserrat14-semibold;
		@extend %link-hover;
		white-space: nowrap;
	}
	&__currency, &__language {
		position: relative;
	}

	// * header__icons
	&__icons {
		margin-left: em(56);
		display: grid;
		grid-template-columns: repeat(3, auto);
		gap: em(24);

		@media (max-width: $tablet) {
			margin: 0;
			gap: rem(16);
			margin-left: auto;
		}

		@media (min-width: $tablet) and (max-width: 1100px) {
			font-size: rem(8);
		}

		a {
			@extend %flex-center;
			width: rem(32);
			height: rem(32);
			border-radius: 100%;
			border: 1px solid $white;
			transition: all $defaultTransition;
			position: relative;

			@media (max-width: $tablet) {
				width: rem(24);
				height: rem(24);
			}

			&:hover {
				border-color: $bazaar;
				svg path {
					fill: $bazaar;
				}
			}
			& > img, svg {
				width: rem(14);
				height: rem(14);
				@media (max-width: $tablet) {
					width: rem(11);
					height: rem(11);
				}
			}
			svg path {
				transition: fill $defaultTransition;
			}
		}
	}

	[data-spoller] {
		display: flex;
		align-items: center;
		cursor: pointer;

		span, a {
			@extend %Montserrat12-16-medium;
			@extend %link-hover;
			transform: uppercase;
		}

		&._spoller-active {
			svg {
				transform: rotate(180deg)
			}
		}
		svg {
			margin-left: em(8);
			transition: transform $defaultTransition;
		}
		& + * {
			position: absolute;
			top: 110%;
			left: 0;
			background: $white;
			color: $woodsmoke;
			padding: em(12);
			max-height: rem(132);

			li {
				&:not(:last-child) {
					margin-bottom: em(12);
				}
			}
			a, span {
				@extend %Montserrat12-16-medium;
				transform: uppercase;
				@extend %link-hover;
			}
		}
	}

	// modeficators
	&_reverse {
		color: $bazaar;

		.header__logo {
			svg {
				path {
					fill: $bazaar;
				}
			}
			&:hover {
				svg path {
					fill: $white;
				}
			}
		}
		[data-spoller] svg {
			path {
				fill: $bazaar;
			}
		}
		[data-spoller] span {
			&:hover {
				color: $white;
			}
		}
		.header__icons {
			a {
				border-color: $bazaar;
				&:hover {
					border-color: $white;
					svg path {
						fill: $white;
					}
				}
			}
			svg path {
				fill: $bazaar;
			}
		}
		.menu__list {
			& > li {
				& > a, span {
					&:hover {
						color: $white;
					}
				}
			}
		}
		.menu__subitem {
			a, span {
				&:hover {
					color: $bazaar;
				}
			}
		}
		.header__phone:hover {
			color: $white;
		}
		.icon-menu {
			span,
			&::before,
			&::after {
				background-color: $bazaar;
			}
		}
	}
	&_white {
		color: $white;

		.header__logo {
			svg {
				path {
					fill: $white;
				}
			}
			&:hover {
				svg path {
					fill: $bazaar;
				}
			}
		}
		[data-spoller] svg {
			path {
				fill: $white;
			}
		}
		[data-spoller] span {
			&:hover {
				color: $bazaar;
			}
		}
		.header__icons {
			a {
				border-color: $white;
				&:hover {
					border-color: $bazaar;
					svg path {
						fill: $bazaar;
					}
				}
			}
			svg path {
				fill: $white;
			}
		}
		.menu__list {
			& > li {
				& > a, span {
					&:hover {
						color: $bazaar;
					}
				}
			}
		}
		.menu__subitem {
			a, span {
				&:hover {
					color: $bazaar;
				}
			}
		}
		.header__phone:hover {
			color: $bazaar;
		}
		.icon-menu {
			span,
			&::before,
			&::after {
				background-color: $white;
			}
		}
	}

}

// * MENU 
.menu {
	&__list {
		display: grid;
		grid-template-columns: repeat(4, auto);
		gap: em(32);

		@media (min-width: $tablet) and (max-width: 1100px) {
			font-size: rem(8);
		}

		& > li {
			display: flex;
			align-items: center;
			cursor: pointer;
			a, span {
				@extend %Montserrat12-16-medium;
				text-transform: uppercase;
				@extend %link-hover;
			}
		}
	}
	&__subitem {
		position: relative;
	}
}

// * BURGER
.icon-menu {
	display: none;
	@media (max-width: $tablet) {
		display: block;
		position: relative;
		flex: 0 0 16px;
		height: 12px;
		cursor: pointer;
		span,
		&::before,
		&::after {
			content: "";
			transition: all 0.3s ease 0s;
			left: 0px;
			position: absolute;
			width: 100%;
			height: 2px;
			background-color: $white;
		}
		&::before {
			top: 0px;
		}
		&::after {
			bottom: 0px;
			width: 45%;
		}
		span {
			top: calc(50% - 1px);
			width: 75%;
		}
		.menu-open & {
			span {
				transform: scale(0);
			}
			&::before {
				transform: rotate(-45deg);
				top: calc(50% - 1px);
			}
			&::after {
				transform: rotate(45deg);
				bottom: calc(50% - 1px);
			}
		}
	}
}