.select {
	@extend %Montserrat14-medium;
	position: relative;
	// .select__body
	&__body {
		position: relative;
	}
	// .select__title
	&__title {
		color: inherit;
		text-align: left;
		border: 1px solid $grey;
		background-color: $white;
		cursor: pointer;
		width: 100%;
	}
	// .select__value
	&__value {
		text-transform: uppercase;
		display: flex;
		align-items: center;
		height: rem(56);
		padding: 0px em(16);
		justify-content: space-between;
		flex-direction: row-reverse;
		&:before {
			content: "";
			align-self: stretch;
			flex: 0 0 rem(16);
			transition: all 0.3s ease 0s;
			display: flex;
			justify-content: center;
			align-items: center;
			background-image: url('../img/interface/shevron-black.svg');
			background-repeat: no-repeat;
			background-position: center;

			._select-open & {
				transform: rotate(180deg);
			}
		}
	}
	// .select__text
	&__text {
		flex: 1 1 auto;
	}
	&__input {
		width: 100%;
		background-color: transparent;
		height: 100%;
	}
	// .select__options
	&__options {
		overflow-y: auto;
		overflow-x: hidden;
		position: absolute;
		top: rem(60);
		min-width: 100%;
		left: 0;
		background-color: $white;
		border: 1px solid $disabled;
		padding: rem(16) rem(36) rem(16) rem(16);
		max-height: rem(156);
	}
	// .select__scroll
	&__scroll {
		overflow-y: auto;
		overflow-x: hidden;
		// Максимальная высота
		max-height: rem(156);
		// Стили скроллбара
		.simplebar-scrollbar {
			&::before {
				opacity: 1;
			}
		}
	}
	// .select__option
	&__option {
		width: 100%;
		text-align: left;
		cursor: pointer;
		color: inherit;
		text-transform: uppercase;
		transition: $defaultTransition;

		&._select-selected {
			background-color: #eee;
		}
		&:hover {
			color: $bazaar;
		}
	}

	// Конструкция дополнительных данных
	&__row {
		display: inline-flex;
		align-items: center;
	}
	&__asset {
	}
	&__text {
	}

	// Состояния селекта
	&._select-open {
		z-index: 5;
	}
	&._select-disabled {
	}
	&._select-active {
	}
	&._select-focus {
	}
	&._select-multiple {
	}
	&._select-checkbox {
	}
	&__label {
		display: none;
	}
}

// Тег
._select-tag {
	cursor: pointer;
}
